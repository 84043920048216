import { createApp } from "vue";
import App from "./App.vue";
import runtime from "serviceworker-webpack-plugin/lib/runtime";
if ("serviceWorker" in navigator) {
    runtime.register();
}

  

// const app = createApp(App);
// app.config.globalProperties.$workbox = wb;
createApp(App).mount("#app");
