<template>
	<div class="urlCard" :style="color">
		<span class='starred' @click="updateStar(id)" :class="{'active': url.starred}"></span>
		<h4>
			<a :href="url.URL" target="_blank">{{ url.Name }}</a>
		</h4>
		<h2 class="website-link">
			<a :href="url.URL" target="_blank">{{ url.URL }}</a>
		</h2>
		<p>{{ url.Details }}</p>
		<div class="tags">
			<span v-for="tag in url.Tags" :key="tag">{{ tag }}</span>
		</div>
	</div>
</template>

<script lang="ts">
	export default {
		name: "Card",
		props: {
			url: Object,
			id: String,
			color: String,
		},
		emits: ['star'],
		methods:{
			updateStar: function(id){
				this.$emit('star', id);
			}
		}
	};
</script>