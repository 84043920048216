<template>
	<Header v-model="filter.search"></Header>
	<div class="container-fluid mt-5">
		<div class="row">
			<div class="col-md-2 d-none d-md-block bg-light sidebar">
				<div class="sidebar-sticky">
					<h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
						<span>Filter By Category ({{ categories.length }})</span>
						<a class="d-flex align-items-center text-muted" href="#">
							<span data-feather="plus-circle"></span>
						</a>
					</h6>
					<ul class="nav flex-column" id="filter-list">
						<li class="nav-item">
							<a class="nav-link filter" data-filter="all" href="javascript:;" :class="{ active: filter.category.length == 0 }" @click="toggleCategory('All')"> <span data-filter="all"></span>All </a>
						</li>
						<template v-for="c in categories" :key="c">
							<li class="nav-item">
								<a class="nav-link filter" :data-filter="c" href="javascript:;" :class="{ selected: filter.category.includes(c) }" @click="toggleCategory(c)" :style="catColor(c)"> <span data-filter="all"></span>{{ c }} </a>
							</li>
						</template>
					</ul>
					<!--
					<h4>Tags</h4>
					<div class="mb-3">
						<a v-for="t in tags" :key="t" @click="filter.tag = t" class="badge bg-secondary">{{ t }}</a>

						<a @click="filter.tag = ''" class="badge badge-pill badge-danger">Clear Tag</a>
					</div>
					-->
				</div>
			</div>
			<main class="col-md-9 ml-sm-auto col-lg-10 px-4">
				<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom d-filters">
					<h4>
						Userful Links <small>{{ searchCount }}</small>
					</h4>

					<div class="btn-group btn-group-sm" role="group" aria-label="Basic radio toggle button group">
						<label class="btn btn-outline-secondary" :class="{ active: filter.type === 'All' }"
							>Both
							<input type="radio" class="btn-check" value="All" checked v-model="filter.type" />
						</label>
						<label class="btn btn-outline-secondary" :class="{ active: filter.type === 'Designer' }"
							>Designers
							<input type="radio" class="btn-check" value="Designer" v-model="filter.type" />
						</label>
						<label class="btn btn-outline-secondary" :class="{ active: filter.type === 'Developer' }"
							>Developers
							<input type="radio" class="btn-check" value="Developer" v-model="filter.type" />
						</label>
					</div>

					<label class="center switch-label">
						Show Only Starred
						<input type="checkbox" v-model="filter.starred" />
					</label>
				</div>
				<div id="mediaLinks" class="row">
					<template v-for="url in searchFilter" :key="url.id">
						<div class="col-md-4">
							<Card :key="url.id + '_' + update" :url="url.fields" :id="url.id" @star="manageStar($event)" :color="catColor(url.fields.Category)" />
						</div>
					</template>
				</div>
			</main>
		</div>
	</div>
</template>
<script lang="ts">
	import Card from "./components/Card.vue";
	import Header from "./components/Header.vue";
	import axios from "axios";
	import _ from "lodash";

	export default {
		name: "App",
		data() {
			return {
				prompt: false,
				update: 0,
				allItems: [],
				filteresItems: [],
				airtable: {
					id: "appJQm9zlSms7nAfP",
					key: "keykHD6CWKJ8mnEw3",
				},
				filter: {
					category: [],
					tag: "",
					search: "",
					type: "All",
					starred: false,
				},
				starred: [],
				categories: [],
				tags: [],
				colours: ["#f1c40f", "#2ecc71", "#3498db", "#1abc9c", "#9b59b6", "#34495e", "#16a085", "#27ae60", "#2980b9", "#8e44ad", "#2c3e50", "#e67e22", "#e74c3c", "#95a5a6", "#f39c12", "#d35400", "#c0392b", "#bdc3c7", "#7f8c8d"],
			};
		},
		components: {
			Card,
			Header,
		},
		mounted() {
			this.showUpgradeUI = false;
			// await this.$workbox.messageSW({ type: "SKIP_WAITING" });
			this.checkStorage();
		},
		computed: {
			searchCount: function() {
				const total = this.allItems.length;
				const final = this.searchFilter.length;
				if (total == final) {
					return " (Total : " + total + ")";
				} else {
					return " (Filtered : " + final + ")";
				}
			},
			searchFilter: function() {
				const self = this;
				let searchList = self.allItems;
				const s = self.filter.search.toLowerCase().trim();
				if (self.filter.search.length > 0) {
					searchList = searchList.filter((f) => {
						return f.fields.Name.toLowerCase().includes(s);
					});
				}

				if (self.filter.category.length > 0) {
					searchList = searchList.filter(function(e) {
						// console.log(e);
						return this.indexOf(e.fields.Category) >= 0;
					}, self.filter.category);
				}

				if (self.filter.type !== "All") {
					searchList = _.filter(searchList, function(f) {
						return f.fields.Type === self.filter.type;
					});
				}

				if (self.filter.tag.length > 0) {
					searchList = _.filter(searchList, function(f) {
						return f.fields.Tags === self.filter.Tags;
					});
				}

				if (self.filter.starred) {
					searchList = _.filter(searchList, function(f) {
						return f.fields.starred === self.filter.starred;
					});
				}
				return searchList;
			},
		},
		methods: {
			manageStar: function(id) {
				if (this.starred.includes(id)) {
					const index = this.starred.indexOf(id);
					if (index > -1) {
						this.starred.splice(index, 1);
					}
				} else {
					this.starred.push(id);
				}
				localStorage.setItem("DDog_star", JSON.stringify(this.starred));
				this.manageData();
			},
			catColor: function(cat) {
				const self = this;
				const index = self.categories.indexOf(cat);
				const colorLength = self.colours.length;
				let color = self.colours[index % colorLength];
				if (typeof color === "undefined") {
					color = "#e5e5e5";
				}
				return "--color:" + color;
			},
			toggleCategory: function(val) {
				const self = this;
				if (val === "All") {
					self.filter.category = [];
				} else {
					if (self.filter.category.includes(val)) {
						const index = self.filter.category.indexOf(val);
						if (index > -1) {
							self.filter.category.splice(index, 1);
						}
					} else {
						self.filter.category.push(val);
					}
				}
				// self.filter.category = val;
				window.scrollTo({ top: 0, behavior: "smooth" });
			},
			manageData: function() {
				const self = this;
				self.filteresItems = self.allItems;

				if (localStorage.getItem("DDog_star")) {
					this.starred = JSON.parse(localStorage.getItem("DDog_star"));
					self.allItems.map((item) => {
						item.fields.starred = this.starred.includes(item.id);
					});
				}

				// Get categories
				let cat = _.flatMap(self.allItems, "fields.Category");
				cat = _.uniq(cat);
				self.categories = _.compact(cat).sort();

				// Get Tags
				/*
				let tag = _.flatMap(self.allItems, "fields.Tags");
				tag = _.uniq(tag);
				self.tags = _.compact(tag);
				*/
			},
			checkStorage: function() {
				const self = this;
				if (localStorage.getItem("DDog_date")) {
					const todays = new Date().getDate().toString();
					const currentDate = localStorage.getItem("DDog_date");
					if (currentDate === todays) {
						self.allItems = JSON.parse(localStorage.getItem("DDog_links"));
						self.manageData();
					} else {
						self.loadLinks();
					}
				} else {
					self.loadLinks();
				}
			},
			loadLinks: function(offset = "") {
				// Init variables
				const self = this;
				const app_id = self.airtable.id;
				const app_key = self.airtable.key;
				axios
					.get("https://api.airtable.com/v0/" + app_id + "/Links?offset=" + offset, {
						headers: { Authorization: "Bearer " + app_key },
					})
					.then(function(response) {
						// console.log(response);
						self.allItems = _.union(self.allItems, response.data.records);
						// Loop throght offsets to get All the records
						if (response.data.offset != undefined) {
							// console.log(response.data.offset);
							self.loadLinks(response.data.offset);
						}

						// Get Tags and Categories after fetching all the records
						if (response.data.offset == undefined) {
							localStorage.setItem("DDog_links", JSON.stringify(self.allItems));
							localStorage.setItem("DDog_date", new Date().getDate().toString());
							self.manageData();
						}
					})
					.catch(function(error) {
						console.log(error);
					});
			},
		},
	};
</script>
