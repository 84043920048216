<template>
	<!--
    <nav class="navbar navbar-dark fixed-top bg-dark flex-md-nowrap p-0 shadow">
        <a class="navbar-brand col-3 col-md-2 mr-0 text-center" href="javascript:;">The D Dog</a>
        <div class="col-9 col-md-10 mr-0">
            <input class="form-control form-control-dark" type="text" placeholder="Search" v-model="search"  aria-label="Search">
        </div>
    </nav>
    -->

	<header class="bg-dark text-white fixed-top p-0 d-header">
		<div class="container-fluid p-0">
			<div class="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
				<a class="navbar-brand col-4 col-md-2 mr-0 text-center p-0" href="javascript:;">The D Dog</a>

				<!--
				<ul class="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0">
					<li><a href="#" class="nav-link px-2 text-secondary">Home</a></li>
					<li><a href="#" class="nav-link px-2 text-white">Features</a></li>
					<li><a href="#" class="nav-link px-2 text-white">Pricing</a></li>
					<li><a href="#" class="nav-link px-2 text-white">FAQs</a></li>
					<li><a href="#" class="nav-link px-2 text-white">About</a></li>
				</ul>
				-->
				<div class="col-md-10 col-8">
					<input type="search" class="form-control form-control-dark" v-model="content" placeholder="Search..." @input="handleInput"  />
				</div>
				<!--
				<div class="text-end">
					<button type="button" class="btn btn-outline-light me-2">Login</button>
					<button type="button" class="btn btn-warning">Sign-up</button>
				</div>
				-->
			</div>
		</div>
	</header>
</template>
<script lang="ts">
	export default {
		name: "Header",
		props: {
			modelValue: String
		},
		emits: ['update:modelValue'],
		data() {
			return {
				content: this.modelValue,
			};
		},
		methods: {
            handleInput: function () {
                this.$emit('update:modelValue', this.content)
            }
        }
	};
</script>
